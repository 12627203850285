import * as React from 'react';
import CountUp from 'react-countup';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

// import aboutUs from '../../../assets/images/about-us.png';
import './statistics.scss';

interface StatisticsCountAreaProps {
  value: number;
  title: string;
  subtitle: string;
  iconName: string;
}

const StatisticsCountArea: React.FC<StatisticsCountAreaProps> = (props) => {
  const { value, title, subtitle, iconName } = props;

  return (
    <div className='count-item'>
      <h1>
        <CountUp delay={2} end={value} decimal='.' duration={4.5} />
        <span>+</span>
      </h1>
      <div className='count-item-description'>
        <BasicIcon iconName={iconName} />
        <div className='count-item-description-text'>
          <h5>
            <b>{title}</b>
          </h5>
          <h6>{subtitle}</h6>
        </div>
      </div>
    </div>
  );
};

const Statistics: React.FC = () => {
  return (
    <div id='statistics' className='container'>
      <div className='content reveal'>
        <div className='title'>Nossos números</div>
        <div className='count-area'>
          <StatisticsCountArea
            value={50000}
            title='Usuários'
            subtitle='Servidos com nosso portfolio'
            iconName='FaUsers'
          />
          <StatisticsCountArea
            value={60}
            title='Projetos'
            subtitle='Concluídos para nossos clientes'
            iconName='FaFlagCheckered'
          />
          <StatisticsCountArea
            value={50}
            title='Clientes'
            subtitle='Satisfeitos com nossas soluções'
            iconName='FaPeopleGroup'
          />
          <StatisticsCountArea value={5} title='Anos' subtitle='De atuação no Mercado' iconName='FaBusinessTime' />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
