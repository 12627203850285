import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';

import './contactWidget.scss';

const ContactWidget: React.FC = () => {
  return (
    <div className='contact-widget'>
      {window.innerWidth < 1200 ? (
        <a
          className='qlwapp-toggle'
          data-action='open'
          data-phone='5577988078935'
          data-message='Olá, gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
          role='button'
          target='_blank'
          rel='noreferrer'
          href='https://api.whatsapp.com/send?phone=5577988078935&text=Ol%C3%A1,
         gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
        >
          <div className='contact-widget-message'>Fale com um especialista!</div>
          <div className='contact-widget-button'>
            <FaWhatsapp />
          </div>
        </a>
      ) : (
        <a
          className='qlwapp-toggle'
          data-action='open'
          data-phone='5577988078935'
          data-message='Olá, gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
          role='button'
          target='_blank'
          rel='noreferrer'
          href='https://web.whatsapp.com/send?phone=5577988078935&amp;text=Olá, gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
        >
          <div className='contact-widget-message'>Fale com um especialista!</div>
          <div className='contact-widget-button'>
            <FaWhatsapp />
          </div>
        </a>
      )}
    </div>
  );
};

export default ContactWidget;
