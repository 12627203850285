import * as React from 'react';

import './home.scss';

{
  /* eslint-disable */
}
const Home: React.FC = () => {
  return (
    <div id='home' className='container'>
      <div className='background-image'>
        <div className='content'>
          <div className='text'>
            <p className='title'>EPSYS</p>
            <div className='content-description'>
              <hr className='description-sub'/>
                <p className='description'>O Futuro é App, Sys e Tech</p>
              <hr className='description-sub'/>
            </div>
            <p className='second-description typewriter'>Transformando negócios por meio do digital.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
