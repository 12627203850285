import * as React from 'react';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import Blocked from 'pages/blocked/blocked';
import Blog from 'pages/blog/listing/listing';
import Post from 'pages/blog/post/post';
import Institucional from 'pages/institucional/institucional';
import PannelContacts from 'pages/pannelContacts/pannelContacts';
import PannelPosts from 'pages/pannelPosts/pannelPosts';
import Signin from 'pages/signin/signin';

import MainContext from 'contexts/main.context';

import { PrivateRoute } from './private.routes';

export const MainRoute: React.FC = () => {
  return (
    <Router>
      <MainContext>
        <Routes>
          {/* <Route path='/em-breve' element={<Blocked />} /> */}
          <Route path='/' element={<Institucional />} />
          {/* <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:viewId' element={<Post />} /> */}

          {/* <Route path='/admin/blog/novo' element={<PrivateRoute element={<Post />} title='' />} />
          <Route path='/admin/blog/editar/:id' element={<PrivateRoute element={<Post />} title='' />} />

          <Route path='/admin' element={<Signin />} />
          <Route path='/admin/painel' element={<PrivateRoute element={<PannelPosts />} title='Pannel' />} />
          <Route path='/admin/contatos' element={<PrivateRoute element={<PannelContacts />} title='Contacts' />} /> */}
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </MainContext>
    </Router>
  );
};
