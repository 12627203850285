import React from 'react';

import { Auth } from 'contexts/auth.context';

import { AuthLoading } from 'components/loadings/authLoading/authLoading';

import { NavigatorContext } from '../../contexts/navigator.context';

import BackToTop from './components/backToTop/backToTop';
import ContactWidget from './components/contactWidget/contactWidget';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import PannelMenu from './components/pannelMenu/pannelMenu';

import './navigator.scss';

interface NavigatorProps {
  children: React.ReactNode;
}

const Navigator: React.FC<NavigatorProps> = (props) => {
  // const { authGrant, pendingUser, user } = React.useContext(Auth);
  const { enabledHeader, enabledBackToTop, enabledWidget, enabledFooter, enabledControlPannel } =
    React.useContext(NavigatorContext);

  // if (!authGrant && pendingUser) {
  //   return <AuthLoading fullscreen />;
  // }

  return (
    <div className='navigator'>
      {enabledHeader && <Header />}
      {enabledBackToTop && <BackToTop />}
      {enabledWidget && <ContactWidget />}
      <div className='navigator-area'>
        {/* {user && enabledControlPannel ? (
          <React.Fragment>
            <PannelMenu />
            <div className='navigator-area-with-pannel'>{props.children}</div>
          </React.Fragment>
        ) : (
          props.children
        )} */}
        {props.children}
      </div>
      {enabledFooter && <Footer />}
    </div>
  );
};

export default Navigator;
