import React from 'react';

import ReactDOM from 'react-dom/client';

import reportWebVitals from 'services/reportWebVitals';

import { MainRoute } from 'routes/main.routes';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MainRoute />
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
