import React from 'react';

import { useLocation } from 'react-router-dom';

import { NavigatorContext } from 'contexts/navigator.context';

import { reveal } from 'utils/reveal';

import AboutUs from './aboutUs/aboutUs';
import Expertise from './expertise/expertise';
import Home from './home/home';
import LeadCapture from './leadCapture/leadCapture';
import Statistics from './statistics/statistics';

import './institucional.scss';

const Institucional: React.FC = () => {
  const { setterNavigator, setterEnableAdminNav, setterEnableControlPannel } = React.useContext(NavigatorContext);

  // const { posts } = usePosts();

  const location = useLocation();

  window.addEventListener('scroll', reveal);

  React.useEffect(() => {
    if (location.hash) {
      document.getElementById(location.hash.replace('#', ''))?.scrollIntoView({ behavior: 'auto' });
    }

    setterEnableAdminNav(false);
    setterEnableControlPannel(false);
    setterNavigator('ABSOLUTE');
  }, [location.hash, setterEnableAdminNav, setterEnableControlPannel, setterNavigator]);

  return (
    <div className='institucional'>
      <Home />
      <AboutUs />
      <Statistics />
      <Expertise />
      <LeadCapture />
      {/* {posts.length !== 0 && <Posts posts={posts} />} */}
      {/* <Contact posts={posts.length} /> */}
    </div>
  );
};

export default Institucional;
