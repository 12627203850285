import * as React from 'react';

import aboutUs from '../../../assets/images/about-us.png';

import './aboutUs.scss';

/* eslint-disable */
const AboutUs: React.FC = () => {
  return (
    <div id='sobre-nos' className='container'>
      <div className='content reveal'>
        <div className='title'>Sobre Nós</div>
        <div className='descriptions'>
          <div className='office-description'>
            <p className="office-description-emphasis">
              Desde 2017 otimizando processos através da tecnologia!
            </p>
            <br/>
            <p>
              Nascida na Bahia, a Cansho Softwares surgiu dentro do Instituto Federal da Bahia com a missão de construir soluções inovadoras.
              Desde então seguimos nosso caminho desenvolvendo negócios e transformando processos por meio da tecnologia.
              Em 2022, após um processo de expansão de negócios, nosso time cresceu e a Cansho se tornou a Epsys,
              &nbsp;aumentando nosso portfólio de produtos, de tecnologias e de profissionais.
            </p>
            <br/>
            <p>
            Em nossa visão, a tecnologia precisa ser baseada em acesso democrático para todas as empresas e seus times.
            Para isso nós seguimos três princípios:<br/><br/>
            <b>Be Bold</b>: Construímos com ousadia para superar as expectativas;<br/>
            <b>Shared Experience</b>: Compartilhamos conhecimento e expertise com nossos clientes;<br/>
            <b>Believe in Dreams</b>: Os sonhos dos nossos clientes são nossos sonhos e por isso acreditamos e nos apaixonamos por cada projeto.
            </p>
            <br/>
            <p>
              Estamos transformando os processos de diversas empresas e queremos ajudar você também!
            </p>
          </div>
          <div className='office-impact-image'>
            <img src={aboutUs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
