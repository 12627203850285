import * as React from 'react';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

import './card.scss';

export interface BasicCardProps {
  title: string;
  description: string;
  icon: string;
}

const Card: React.FC<BasicCardProps> = ({ title, description, icon }) => {
  return (
    <div className='card-expertise'>
      <div className='card-expertise-content'>
        <div className='card-expertise-header'>
          <BasicIcon iconName={icon} />
          <div className='card-expertise-title'>{title}</div>
        </div>
        <div className='card-expertise-decriptions'>
          <div className='card-expertise-description'>
            <p className='text'>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
