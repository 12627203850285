import React from 'react';

import { FaTimes } from 'react-icons/fa';
import {
  FaAddressBook,
  FaAngleRight,
  FaArrowRightToBracket,
  FaAt,
  FaBrain,
  FaBuilding,
  FaBusinessTime,
  FaCalendarDays,
  FaCartShopping,
  FaChartSimple,
  FaCirclePlus,
  FaCubesStacked,
  FaDesktop,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaFacebookF,
  FaFileCsv,
  FaFileExcel,
  FaFileExport,
  FaFlagCheckered,
  FaGear,
  FaLinkedin,
  FaLinkedinIn,
  FaMagnifyingGlass,
  FaMicrochip,
  FaMobileScreen,
  FaNetworkWired,
  FaPeopleGroup,
  FaPersonBreastfeeding,
  FaPersonCane,
  FaPersonDigging,
  FaPhone,
  FaRectangleList,
  FaRegCircle,
  FaRegCircleCheck,
  FaRegUser,
  FaSeedling,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareWhatsapp,
  FaT,
  FaTrash,
  FaTree,
  FaTriangleExclamation,
  FaTwitter,
  FaUpload,
  FaUser,
  FaUsers,
  FaUserTie,
} from 'react-icons/fa6';

import './basicIcon.scss';

interface BasicIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconName: string;
}

interface IIcons {
  [string: string]: React.ReactElement;
}

const BasicIcon: React.FC<BasicIconProps> = ({ iconName, ...props }) => {
  const icons: IIcons = {
    FaEye: <FaEye />,
    FaGear: <FaGear />,
    FaUser: <FaUser />,
    FaUsers: <FaUsers />,
    FaUserTie: <FaUserTie />,
    FaPhone: <FaPhone />,
    FaEnvelope: <FaEnvelope />,
    FaRegUser: <FaRegUser />,
    FaEyeSlash: <FaEyeSlash />,
    FaCirclePlus: <FaCirclePlus />,
    FaAngleRight: <FaAngleRight />,
    FaChartSimple: <FaChartSimple />,
    FaRectangleList: <FaRectangleList />,
    FaArrowRightToBracket: <FaArrowRightToBracket />,
    FaT: <FaT />,
    FaTwitter: <FaTwitter />,
    FaFacebookF: <FaFacebookF />,
    FaLinkedinIn: <FaLinkedinIn />,
    FaUpload: <FaUpload />,
    FaTimes: <FaTimes />,
    FaSquareWhatsapp: <FaSquareWhatsapp />,
    FaSquareFacebook: <FaSquareFacebook />,
    FaLinkedin: <FaLinkedin />,
    FaSquareInstagram: <FaSquareInstagram />,
    FaMagnifyingGlass: <FaMagnifyingGlass />,
    FaCalendarDays: <FaCalendarDays />,
    FaAt: <FaAt />,
    FaAddressBook: <FaAddressBook />,
    FaTrash: <FaTrash />,
    FaBuilding: <FaBuilding />,
    FaMicrochip: <FaMicrochip />,
    FaPeopleGroup: <FaPeopleGroup />,
    FaPersonCane: <FaPersonCane />,
    FaBrain: <FaBrain />,
    FaPersonBreastfeeding: <FaPersonBreastfeeding />,
    FaSeedling: <FaSeedling />,
    FaPersonDigging: <FaPersonDigging />,
    FaTree: <FaTree />,
    FaRegCircleCheck: <FaRegCircleCheck />,
    FaRegCircle: <FaRegCircle />,
    FaFileExport: <FaFileExport />,
    FaFileCsv: <FaFileCsv />,
    FaFileExcel: <FaFileExcel />,
    FaTriangleExclamation: <FaTriangleExclamation />,
    FaBusinessTime: <FaBusinessTime />,
    FaFlagCheckered: <FaFlagCheckered />,
    FaNetworkWired: <FaNetworkWired />,
    FaMobileScreen: <FaMobileScreen />,
    FaCartShopping: <FaCartShopping />,
    FaDesktop: <FaDesktop />,
    FaCubesStacked: <FaCubesStacked />,
  };

  return <i {...props}> {icons[iconName]}</i>;
};

export default BasicIcon;
