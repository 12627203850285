import * as React from 'react';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Card from './components/card';

import './expertise.scss';

/* eslint-disable */
const Expertise: React.FC = () => {
  const dataExpertise = [
    {
      title: 'Softwares Personalizados',
      description:
        'Criamos soluções de software sob medida para atender aos requisitos da sua empresa. Desenvolvemos softwares funcionais, escaláveis e altamente eficientes.',
      icon: 'FaCubesStacked',
    },
    {
      title: 'Aplicativos Móveis',
      description:
        'Desenvolvemos aplicativos móveis para iOS e Android que agregam valor ao seu negócio. Melhore a experiência do cliente, aumente a produtividade interna e expanda seu alcance no mercado.',
      icon: 'FaMobileScreen',
    },
    {
      title: 'Lojas Viruais',
      description:
        'Desenvolvemos plataformas de comércio eletrônico robustas, seguras e fáceis de usar, que proporcionam uma experiência de compra excepcional aos seus clientes.',
      icon: 'FaCartShopping',
    },
    {
      title: 'Landing Pages',
      description:
        'Precisa de uma landing page? Construímos a sua página com design personalizado para transmitir exatamente o seu posicionamento no mercado.',
      icon: 'FaDesktop',
    }
  ];
  return (
    <div id='especialidades' className='container'>
      <div className='content reveal'>
        <div className='title-content'>
          <div className='title'>Soluções Tecnológicas que Oferecemos</div>
          <Card title='Integração de Sistemas' description='Integramos sistemas existentes ou implementamos novas soluções que garantam a comunicação fluida entre diferentes departamentos e processos da sua empresa.' icon='FaNetworkWired' />
        </div>
        <div className='cards-expertise'>
          {dataExpertise.map((card, index) => (
            <Card key={index} title={card.title} description={card.description} icon={card.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Expertise;
