import * as React from 'react';
import logoStandardTransparentFit from 'assets/images/logoStandardTransparentFit.png';

import { format } from 'date-fns';

import './footer.scss';

const Footer: React.FC = () => {
  return (
    <div id='footer' className='container'>
      <div className='background-image'>
        <div className='content'>
          <div className='navigation-footer'>
            <div className='social-footer'>
              <div className='logo-footer'>
                <img src={logoStandardTransparentFit} width={'150px'} />
              </div>
              <h4 className='social-footer-impact-message'>
                O Futuro é
                <br /> App, Sys e Tech!
              </h4>
            </div>
            <div className='menu-footer'>
              <div className='menu-footer-title'>Menu</div>
              <div className='menu-footer-links'>
                <a href='/#home'>Home</a>
                <a href='/#sobre-nos'>Sobre nós</a>
                <a href='/#especialidades'>Especialidades</a>
                <a href='/#projetos'>Projetos</a>
              </div>
            </div>
            <div className='contact-footer'>
              <div className='contact-footer-title'>Contato</div>
              <div className='contact-footer-descriptions'>
                <div>
                  <p>Avenida Brigadeiro Luis Antonio, Nº 2367 - Sala 911</p>
                  <p>Jardim Paulista, São Paulo - SP - 01401-900</p>
                </div>
                <a
                  className='qlwapp-toggle'
                  data-action='open'
                  data-phone='5577988078935'
                  data-message='Olá, gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
                  role='button'
                  target='_blank'
                  rel='noreferrer'
                  href='https://api.whatsapp.com/send?phone=5577988078935&text=Ol%C3%A1,
                   gostaria de realizar um orçamento com o intuito de promover a minha empresa.'
                >
                  (77) 98807-8935
                </a>
                {/* <a href='mailto:contato@froesecalazans.com.br'>contato@froesecalazans.com.br</a> */}
              </div>
            </div>
          </div>
        </div>
        <div className='container-footer'>
          <div className='powered-footer'>
            <div className='programming-footer'>
              Desenvolvido por EPSYS
              <a target='_blank' rel='noreferrer' href='http://epsys.io/'>
                EPSYS
              </a>
            </div>
            <div className='rights-footer'>
              <div>Todos os direitos reservados | 2017 - {format(new Date(), 'yyyy')} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
