import React from 'react';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import agro from '../../../assets/images/agro.png';
import cansho from '../../../assets/images/cansho.png';
import fdv from '../../../assets/images/fdv.png';

import './leadCapture.scss';

const LeadCapture: React.FC = () => {
  return (
    <React.Fragment>
      <div id='projetos' className='budgetPanel reveal'>
        <div className='backgroundImage' />
        <div className='budgetContent'>
          <h1>Faça agora seu Orçamento</h1>
          <p>
            Vamos montar uma proposta personalizada para sua demanda, com um mapa sistema e descrição de cada
            funcionalidade, prazo de conclusão e custo.
          </p>
          <BasicButton
            type='button'
            label='Fazer Orçamento'
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=5577988078935&text=Ol%C3%A1, gostaria de realizar um orçamento com o intuito de promover a minha empresa.',
                '_blank'
              )
            }
          />
        </div>
      </div>
      <div className='container-default-lead reveal'>
        <div className='title-lead'>Alguns de Nossos Projetos</div>
        <div className='content-lead'>
          <div className='container-lead'>
            <div className='first-container'>
              <div className='container-img-1'>
                <BasicButton
                  type='button'
                  label='PREVIEW SITE'
                  onClick={() => window.open('https://vendas.canshogroup.com.br/', '_blank')}
                />
              </div>
              <img src={fdv} />
              <div className='container-text'>
                <div className='title'>Força de Vendas</div>
                <div className='description'>Plataforma para gestão de pedidos, separação e expedição de pedidos.</div>
              </div>
            </div>
            <div className='second-container'>
              <div className='container-img'>
                <BasicButton
                  type='button'
                  label='PREVIEW SITE'
                  onClick={() => window.open('https://agro.canshogroup.com.br/', '_blank')}
                />
              </div>
              <img src={agro} />
              <div className='container-text'>
                <div className='title'>Força Agro</div>
                <div className='description'>
                  Plataforma Tecnológica de baixo custo para a gestão completa da produção no agronegócio.
                </div>
              </div>
            </div>
            <div className='third-container'>
              <div className='container-img-2'>
                <BasicButton
                  type='button'
                  label='PREVIEW SITE'
                  onClick={() => window.open('https://canshogroup.com.br/', '_blank')}
                />
              </div>
              <img src={cansho} />
              <div className='container-text'>
                <div className='title'>Cansho Softwares</div>
                <div className='description'>Landing pages e sites institucionais</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadCapture;
